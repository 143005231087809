<template>
  <div class="content">
    <div class="list-wrap" v-if="messageList.length">
      <el-collapse v-model="activeKey">
        <el-collapse-item :key="item.id" v-for="item in messageList">
          <div slot="title" class="title-wrap" @click="handleChange(item)">
            <p>{{ item.create_at }}</p>
            <p v-if="showType === '1'">{{ item.title }}</p>
            <p v-html="item.content"></p>
            <i v-if="!item.is_open"></i>
          </div>
          <div class="conntent-wrap">
            <div class="msg-img" v-if="item.img">
              <!-- <viewer ref="viewer" class="img-view" :images="[item.img]">
                <img :src="item.img" alt="" />
              </viewer> -->
              <el-image
                style="width: 120px; height: 120px"
                :src="item.img"
                :preview-src-list="[item.img]">
              </el-image>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div v-else class="empty">暂无通知</div>
    <Pagination
      v-if="messageList.length"
      :total="pager.total"
      :limit.sync="pager.page_size"
      :page.sync="pager.page"
      :hidden-page="false"
      @pagination="handlePageChange"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
// featchMessagCount,MessageRead,MessageAllRead,deleteMessage,deleteAllMessage
import { fetchMessageList, MessageRead } from '@/api/message'

export default {
  name: 'MessageList',
  components: {
    Pagination
  },
  data() {
    return {
      pager: {
        total: 0,
        page: 1,
        page_size: 30
      },
      messageList: [],
      activeKey: [],
      loading: false
    }
  },
  props: {
    showType: String
  },

  computed: {},
  mounted() {
    this.getMessageList()
  },
  methods: {
    // 获取消息列表
    async getMessageList() {
      try {
        const params = {
          type: this.showType,
          page: this.pager.page
        }
        const {  data: { list, total } } = await fetchMessageList(params)
        this.messageList = list
        this.pager.total = total
      } catch (e) {
        console.log(e)
      }
    },
    // 标记已读
    async handleChange(val) {
      if(val.is_open) return
      try {
        const {code } = await MessageRead({ id: val.id })
        if (code == 0) {
          this.messageList.find((i) => i.id === val.id).is_open = 1
          this.$store.dispatch('message/updateMessageCount')
        }
      } catch (e) {
        console.log(e)
      }
    },
    /**
     * 切换页码
     * @param e.page 页码
     */
    handlePageChange(e) {
      this.pager.page = e.page
      this.getMessageList()
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-collapse-item__header {
  height: auto;
}
.list-wrap {
  min-height: 50vh;
  margin-bottom: 20px;
}
.conntent-wrap, .empty {
  padding: 0 20px;
}
.title-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  line-height: 24px;
  position: relative;
  padding: 10px 20px;
  font-size: 14px;
  i {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: red;
    position: absolute;
    right: -12px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
