<template>
  <div class="message-container">
    <div class="message-title">消息中心</div>
    <el-tabs class="tab-container" tab-position="left">
      <el-tab-pane label="系统通知">
        <message-list :show-type="'1'"/>
      </el-tab-pane>
      <el-tab-pane label="活动通知">
        <message-list :show-type="'2'"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import MessageList from "./components/MessageList.vue";

export default {
  name: 'Message',
  components: {
    MessageList
  },
  data() {
    return {
      activeKey: '1',
    }
  },

  computed: {},

  watch: {},

  created() {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>
$max-width: 1200px;
.center {
  width: $max-width;
  margin: 0 auto;
}
.message-container {
  padding-top: 70px;
  padding-bottom: 80px;
  min-height: calc(100vh - 268px);
  .message-title {
    @extend .center;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(51, 51, 51, 0.6);
    line-height: 28px;
    padding-top: 40px;
    padding-bottom: 24px;
  }
  .tab-container {
    @extend .center;
  }
}


</style>
